




















import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { vxm } from '@/store'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  rowActions = []
  topActions = []

  mounted() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Default',
        value: 'isDefault',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'Settings/Booking/Calendars/Edit',
            params: { calendarId: item.id },
          }
        },
      },
      {
        id: 'setDefault',
        label: 'Set as default',
        click: async(parent, item) => {
          await this.$axios
            .put(this.url + '/' + item.id + '/set-default')
            .then((response) => {
              parent.load()
              vxm.booking.fetchCalendars()
            })
            .catch((err) => {
              vxm.alert.onAxiosError(err)
            })
        },
      },
      {
        id: 'delete',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'Settings/Booking/Calendars/New',
          }
        },
      },
    ]
  }

  get url() {
    return '/v4/site/calendars'
  }
}
